.ck-editor__editable {
  min-height: 150px;
}

.hotel-info {
  display: flex;
  align-items: center;
  position: relative;
}

img.hotel-info-image {
  width: 50px;
}

h3.hotel-info-name {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.hotel-info-data {
  margin-left: 10px;
  width: 400px;
}

.hotel-info {
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.hotel-info-expand {
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.hotel-info-data > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
}

.hotel-info-data > ul > li {
  font-size: 13px;
  margin-right: 17px;
  color: #737373;
}

.hotel-info-data > ul > li i {
  font-size: 12px;
  margin-right: 4px;
}

h3.hotel-info-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.hotel-info-item {
  margin-left: 25px;
}

.hotel-info-item > p {
  margin: 0;
  font-size: 11px;
  color: #636363;
}

.hotel-info-item > span {
  font-size: 12px;
}

.hotel-info-item > span i {
}

.hotel-info-actions {
  position: absolute;
  right: 17px;
  color: #ff7875;
  font-size: 13px;
}

.hotel-row {
  border: 2px solid #40a9ff;
  border-radius: 9px;
  margin-bottom: 10px;
}

.hotel-row-detail {
  padding: 10px 20px 0 20px;
}

.hotel-row {
  border: 2px solid #40a9ff;
  border-radius: 9px;
}

.hotel-row-detail {
  padding: 10px 20px 0 20px;
}

.hotel-info-item span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}

.hotel-info-item span i, .hotel-info-item span img {
  height: 17px;
  margin-right: 4px;
  display: block;
  line-height: 17px;
}

button.add-button {
  width: 100%;
  background: none;
  border: 1px dashed #b9b9b9;
  border-radius: 6px;
  color: #949494;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  padding: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.col-images img {
  height: 18px;
}

.col-images span {
  margin-left: 3px;
  font-size: 11px;
  font-weight: 300;
  color: black;
}

.col-promotions a {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col-promotions a i {
  color: #b7b7b7;
  font-size: 10px;
  margin-right: 3px;
}

.col-name > a {
  font-size: 13px;
}

.col-name > span {
  display: block;
  font-size: 11px;
  color: #949494;
}

.col-promotions {
  display: flex;
}

.col-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form-actions > button {
  margin-right: 12px;
}

.col-name {
  max-width: 250px;
}