.panel-head-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.panel-head-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.item-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.item-row-info {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.add-new-row {
  font-size: 13px;
  cursor: pointer;
}

.add-new-row > span {
  margin-right: 6px;
}

.input-name-container {
  flex: 1;
  width: 100%;
  display: flex;
  padding-left: 20px;
}

img.input-name-item-img {
  width: 24px;
}

.input-name-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.panel-head-input {
  width: calc(100% - 50px);
}

//.ant-form-item-label {
//  width: 100% !important;
//  text-align: left !important;
//}
