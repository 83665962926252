.app, #root {
    height: 100%;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 5px !important;
    font-size: 12px;
}

.ant-table-tbody > tr > td a {
    font-weight: 500;
}

.ant-table-tbody > tr > td b {
    font-weight: 500;
}

.one-line {
    white-space: nowrap;
}

.tag-view {
    color: #1890ff;
}

.room-name {
    color: red;
}

.room-name > b:first-child {
    color: black;
    font-weight: normal;
}

.room-name.not-name {
    color: black;
}