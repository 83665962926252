.login-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}

.login-warp > form {
  max-width: 500px;
  width: 100%;
}

.login-title {
  margin-bottom: 30px;
  margin-top: -50px;
}
