.ck-editor__editable {
  min-height: 150px;
}

.hotel-info {
  display: flex;
  align-items: center;
}

img.hotel-info-image {
  width: 50px;
}

h3.hotel-info-name {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.hotel-info-data {
  margin-left: 10px;
}

.amenities {
  width: 100%;
  margin-bottom: 20px;
}

.amenity-title {
  font-size: 12px;
  color: #b7b7b7;
  margin-bottom: 5px;
}

.room-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ececec;
  /* width: 100%; */
  padding: 5px 10px;
  position: relative;
}

img.room-item-img {
  width: 50px;
}

.room-item-name {
  margin-left: 15px;
  width: 300px;
}

ul.room-item-infos {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

h3.room-item-text-name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.room-item-amenities {
  margin-left: 12px;
  width: 70px;
}

ul.room-item-beds-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.room-item-beds {
  width: 200px;
  margin-left: 12px;
}

ul.room-item-beds-list p {
  font-size: 11px;
  margin: 0;
  color: #7d7d7d;
}

ul.room-item-beds-list span {
}

ul.room-item-beds-list span img {
  width: 16px;
}

ul.room-item-beds-list li {
  margin-right: 10px;
}

.room-item-amenities > p {
  font-size: 11px;
  margin: 0;
  color: #7d7d7d;
}

li.room-item-info {
  margin-right: 20px;
  font-size: 12px;
  color: #bfbfbf;
}

li.room-item-info i {
  margin-right: 4px;
}

li.room-item-info span {
  color: black;
  margin-right: 4px;
}

.room-item-img {
  font-size: 25px;
  margin-right: 12px;
  color: #40a9ff;
}

.col-price > a {
  display: block;
  margin-bottom: 5px;
  color: #424242;
}

.col-price span {
  color: #096dd9;
  display: inline-block;
  margin-left: 5px;
  font-weight: 500;
}